/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, p, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  border-left: 3px solid #ccc;
  margin: 1.5em 10px;
  padding: 0px 10px;
}

blockquote:before, q:before {
  color: #ccc;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scrollbar-color: lighten($ui-base-color, 4%) rgba($base-overlay-background, 0.1);
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: lighten($ui-base-color, 4%);
  border: 0px none $base-border-color;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: lighten($ui-base-color, 6%);
}

::-webkit-scrollbar-thumb:active {
  background: lighten($ui-base-color, 4%);
}

::-webkit-scrollbar-track {
  border: 0px none $base-border-color;
  border-radius: 0;
  background: rgba($base-overlay-background, 0.1);
}

::-webkit-scrollbar-track:hover {
  background: $ui-base-color;
}

::-webkit-scrollbar-track:active {
  background: $ui-base-color;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
